<template>
  <div>
    <table-local-v-1-widget
      v-if="keyName === 'ban'"
      :items="localRows"
      :columns="cColumns"
      :actions="cActions"
      :loading="localLoading"
      @clickView="tableClickView"
    >
      <template #verify_setting_info="{row}">
        <b-badge :variant="statusSettingInfoVariant[row.verify_setting_info.status]">
          {{ statusSettingInfoText[row.verify_setting_info.status] }}
        </b-badge>
      </template>
    </table-local-v-1-widget>

    <table-server-side-v-1-widget
      v-else
      :url="url"
      request-columns-filter-type="base64"
      :columns="cColumns"
      :actions="cActions"
      @clickView="tableClickView"
    >
      <template #verify_setting_info="{row}">
        <b-badge :variant="statusSettingInfoVariant[row.verify_setting_info.status]">
          {{ statusSettingInfoText[row.verify_setting_info.status] }}
        </b-badge>
      </template>
    </table-server-side-v-1-widget>
  </div>
</template>

<script>
export default {
  props: {
    keyName: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusSettingInfoText: {
        'Wait Document': 'รอเอกสารยืนยันตัวตนจากผู้สมัคร AF',
        Submit: 'รอแอดมินอนุมัติเอกสารยืนยันตัวตน',
        Reject: 'ไม่อนุมัติ',
        Approval: 'อนุมัติ',
      },
      statusSettingInfoVariant: {
        'Wait Document': 'secondary',
        Submit: 'warning',
        Reject: 'danger',
        Approval: 'success',
      },
      localRows: [],
      localLoading: false,
    }
  },
  computed: {
    cColumns() {
      const commonColumns = [
        {
          label: 'วันที่สมัคร (วัน-เดือน-ปี)',
          field: 'register_date',
          formatFn: value => this.$date(value).format('DD-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'เบอร์โทรศัพท์',
          field: 'phone_number',
        },
        {
          label: 'ค่าคอมมิชชั่นที่ได้รับทั้งหมด',
          field: 'total_revenue',
          tdClass: 'text-center',
          thClass: 'text-center',
          formatFn: value => this.gFormatNumberToCurrency(value),
        },
      ]

      if (this.keyName === 'all') {
        return [
          ...commonColumns,
          {
            label: 'สถานะเอกสารยืนยันตัวตน',
            field: 'verify_setting_info',
            type: 'custom',
            thClass: 'text-center',
            tdClass: 'text-center',
            sortable: false,
            filterable: false,
            filterOptions: {
              enable: false,
            },
          },
        ]
      }

      return commonColumns
    },
    cActions() {
      return [{ keyEmit: 'clickView', label: 'ดูข้อมูลเพิ่มเติม', iconName: 'EyeIcon' }]
    },
  },
  mounted() {
    if (this.keyName === 'ban') {
      this.fetchLocalDataTable(this.url)
    }
  },
  methods: {
    tableClickView(rowObj) {
      this.$router.push({ name: 'affiliate-user-management-details', params: { id: rowObj.user_id } })
    },
    async fetchLocalDataTable(url) {
      this.localLoading = true
      const resp = await this.api.getV2(url).catch(() => null)
      this.localLoading = false
      if (resp && resp.code === 200 && Array.isArray(resp.data)) {
        this.localRows = [...resp.data]
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
